const DateStatSpreadInfo = {
  sheetName: '日统计',
  columns: [
    {
      label: '子目号',
      dataKey: 'meterageDisplayCode',
      width: '110'
    },
    {
      label: '子目名称',
      dataKey: 'meterageName',
      width: '150'
    },
    {
      label: '单位',
      dataKey: 'unit',
      width: '60'
    },
    {
      label: '清单数量',
      dataKey: 'num',
      width: '100'
    },
    {
      label: '单价',
      dataKey: 'price',
      width: '100'
    },
    {
      label: '金额',
      dataKey: 'amount',
      width: '100'
    },
    {
      label: '月计划',
      dataKey: 'currentMonthPlanNum',
      width: '100'
    },
    {
      label: '月完成',
      dataKey: 'currentMonthStatNum',
      width: 100
    },
    {
      label: '月度累计完成%',
      dataKey: 'currentMonthStatPercent',
      width: '150'
    }
  ]
}

export default DateStatSpreadInfo
