<template>
  <div class="section-date-stat">
    <my-search-bar>
      <el-form :inline="true">
        <el-form-item>
          <el-date-picker
            v-model="reportDate"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyyMMdd"
            style="width: 100%;">
          </el-date-picker>
        </el-form-item>
      </el-form>
    </my-search-bar>
    <my-spread ref="mySpread"
      v-if="defaultDataList.length"
      :default-data-list="defaultDataList"
      :spread-info="spreadInfo">
    </my-spread>
  </div>
</template>

<script>
import axios from 'axios'
import utility from '@/common/utility'
import auth from '@/common/auth'
import MySpread from '@/components/Spread/MySpread'
import MySearchBar from '@/components/MySearchBar/MySearchBar'
import DateStatSpreadInfo from '@/views/MeterageDateStatManage/DateStatSpreadInfo'
import moment from 'moment'
import config from '@/config'

export default {
  name: 'SectionDateStat',
  components: {
    MySpread,
    MySearchBar
  },
  data () {
    return {
      defaultDataList: [],
      spreadInfo: JSON.parse(JSON.stringify(DateStatSpreadInfo)),
      year: '',
      month: '',
      reportDate: ''
    }
  },
  watch: {
    reportDate (newVal) {
      if (newVal) {
        this.year = parseInt(this.reportDate.substr(0, 4))
        this.month = parseInt(this.reportDate.substr(4, 2))
        this.defaultDataList = []
        this.initSpreadInfo()
        this.getData()
      }
    }
  },
  methods: {
    initSpreadInfo () {
      const endDate = moment([ this.year, this.month - 1, config.monthStart ])
      const startDate = endDate.clone().add(-1, 'months').set('date', config.monthStart)
      this.spreadInfo = JSON.parse(JSON.stringify(DateStatSpreadInfo))
      const column = {
        label: '日完成',
        children: []
      }
      for (let start = startDate.clone(); !start.isSame(endDate); start.add(1, 'days')) {
        column.children.push({
          label: start.format('D日'),
          dataKey: start.format('YYYYMMDD'),
          width: 90
        })
        console.log(column.children)
      }
      endDate.subtract(1, 'days')
      this.spreadInfo.columns.push(column)
    },
    getData () {
      axios.get(utility.getRestFullUrl('meterageDateStat', 'monthStat'), {
        params: {
          tenderGuid: auth.getUserInfo().tenderGuid,
          year: this.year,
          month: this.month
        }
      })
        .then(res => {
          const list = res.data.data.dateSummaryStatList.map(monthItem => {
            res.data.data.dateStatList
              .filter(dateItem => dateItem.meterageListGuid === monthItem.meterageListGuid)
              .forEach(dateItem => {
                monthItem[dateItem.reportDate] = dateItem.currentDateStatNum
              })
            return monthItem
          })
          this.defaultDataList = list
        })
        .catch(err => {
          console.log(err)
        })
    }
  },
  created () {
  }
}
</script>

<style scoped lang="scss">
.section-date-stat {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .spread-wrapper {
    flex: 1;
  }
}
</style>
